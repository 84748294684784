@import 'scss/colors';
@import 'scss/reset';
@import 'scss/fonts';
@import 'scss/layout';
@import 'scss/table';
@import 'scss/form';
@import 'scss/alert';
@import 'scss/material-theme';

.clickable-item {
  background-color: #fff;

  &:hover {
    background-color: lighten($secondary-color, 35);
    cursor: pointer;
  }
}

.error-snackbar {
  background-color: #d3302f;
  color: #fff;
  font-weight: normal;

  &.mat-snack-bar-container {
    margin-bottom: 60px;
  }

  .mat-button {
    color: #fff;
  }
}
